import { List, ListSubheader, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { logService } from '../../services/logs';
import { itemMap } from './listItems';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
        maxHeight: `calc(var(--vh, 1vh) * 100 - 72px + env(safe-area-inset-bottom))`,
        marginTop: 'calc(72px + env(safe-area-inset-top))',
        WebkitOverflowScrolling: 'touch',
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
}));

export const Entries = () => {
    const classes = useStyles();
    const [entries, setEntries] = useState([]);

    const loadEntries = () => {
        logService.getGroupedLogs().then((entries) => {
            setEntries(entries);
        });
    };

    useEffect(() => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        loadEntries();
        window.addEventListener('refreshentries', loadEntries);

        return () => {
            window.removeEventListener('refreshentries', loadEntries);
        };
    }, []);

    return (
        <List className={classes.root} subheader={<li />}>
            {entries.map((entry) => (
                <li key={`section-${entry.label}`} className={classes.listSection}>
                    <ul className={classes.ul}>
                        <ListSubheader>{entry.label}</ListSubheader>
                        {entry.logs.map((log) => {
                            const Item = itemMap.has(log.type) ? itemMap.get(log.type) : itemMap.get('default');
                            return <Item key={`item-${log.id}`} log={log} />;
                        })}
                    </ul>
                </li>
            ))}
        </List>
    );
};
