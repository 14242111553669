import { Service } from './Service';

class UserService extends Service {
    localName = 'credentials';
    login = async (data) => {
        const response = await this.post('/auth/login', data);

        if (response.success) {
            this.setLocal(this.localName, JSON.stringify(response.response));
            return response.response;
        }
    };
    logout = async () => {
        this.removeLocal(this.localName);
    };
    validate = async () => {
        try {
            const userData = this.getLocalUserData();
            const response = await this.get('/auth/validate', userData.token);
            return response.success;
        } catch (error) {
            return false;
        }
    };
    getLocalUserData() {
        return this.getLocal(this.localName);
    }
}

export const userService = new UserService();
