const { REACT_APP_API_URL } = process.env;

export class Service {
    apiUrl = '';
    get jwtToken() {
        const credentials = this.getLocal('credentials');

        return credentials ? credentials.token : '';
    }

    constructor() {
        this.apiUrl = `${REACT_APP_API_URL}`;
    }

    get = async (url, token) => {
        const response = await fetch(`${this.apiUrl}${url}`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return await response.json();
    };

    post = async (url, data, token) => {
        try {
            const response = await fetch(`${this.apiUrl}${url}`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(data),
            });

            return await response.json();
        } catch (error) {
            return {
                success: false,
                ...error,
            };
        }
    };

    setLocal(name, value) {
        localStorage.setItem(name, value);
    }
    removeLocal(name) {
        localStorage.removeItem(name);
    }
    getLocal(name) {
        try {
            return JSON.parse(localStorage.getItem(name));
        } catch (error) {
            return;
        }
    }
}
