import format from 'date-fns/format';
import isThisWeek from 'date-fns/isThisWeek';
import isToday from 'date-fns/isToday';
import isYesterday from 'date-fns/isYesterday';
import de from 'date-fns/locale/de';
import parseJSON from 'date-fns/parseJSON';
import { Service } from './Service';

class LogService extends Service {
    getLogs = async () => {
        const logResponse = await this.get('/logs', this.jwtToken);
        return logResponse.response;
    };

    getDateLabel(dateStr) {
        const date = parseJSON(dateStr);
        let result = format(date, 'do LLLL yyyy', { locale: de });
        if (isToday(date)) {
            result = 'Heute';
        } else if (isYesterday(date)) {
            result = 'Gestern';
        } else if (isThisWeek(date)) {
            result = `diesen ${format(date, 'EEEE', { locale: de })}`;
        }
        return result;
    }

    getGroupedLogs = async () => {
        const logs = await this.getLogs();
        if (!logs) return [];
        const groupedMap = new Map();
        const grouped = [];

        logs.forEach((log) => {
            const dateLabel = this.getDateLabel(log.started_at || log.ended_at || log.updated_at);
            const group = groupedMap.has(dateLabel) ? groupedMap.get(dateLabel) : [];
            groupedMap.set(dateLabel, [...group, { ...log }]);
        });

        groupedMap.forEach((value, key) => {
            grouped.push({
                label: key,
                logs: value,
            });
        });

        return grouped;
    };
}

export const logService = new LogService();
