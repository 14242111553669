import { Box, Button, Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useContext } from 'react';
import { StoreContext } from '../../services/Store';
import { userService } from '../../services/user';

const useStyles = makeStyles((theme) => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export const Login = () => {
    const classes = useStyles();
    const [, dispatch] = useContext(StoreContext);

    const handleSubmit = (event) => {
        event.preventDefault();

        const formData = new FormData(event.currentTarget);

        const values = {};
        for (const [name, value] of formData.entries()) {
            values[name] = value;
        }

        userService.login(values).then((response) => {
            dispatch({ type: 'LOG_IN', payload: response });
        });
    };

    return (
        <Box css={{ height: '100vh' }} mx={2} display="flex" alignItems="center">
            <Box>
                <Typography component="h1" variant="h5">
                    LogIn
                </Typography>
                <form className={classes.form} onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="email"
                                variant="outlined"
                                required
                                fullWidth
                                id="email"
                                label="E-Mail"
                                type="email"
                                autoFocus
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="password"
                                variant="outlined"
                                required
                                fullWidth
                                id="password"
                                label="Passwort"
                                type="password"
                            />
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        size="large"
                        className={classes.submit}
                    >
                        Sign Up
                    </Button>
                </form>
            </Box>
        </Box>
    );
};
