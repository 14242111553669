import { ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import { getTimeFromDate } from '../../../utils/date';
import { DiaperIcon } from '../../Icons/DiaperIcon';

export const DiaperItem = ({ log }) => {
    const time = getTimeFromDate(log.started_at || log.ended_at || log.updated_at);

    return (
        <ListItem>
            <ListItemIcon>
                <DiaperIcon width="40px" height="40px" />
            </ListItemIcon>
            <ListItemText
                primary={`${time} Windel`}
                primaryTypographyProps={{ variant: 'body1' }}
                secondary={
                    <>
                        <Typography variant="body2">{log.description}</Typography>
                    </>
                }
            />
        </ListItem>
    );
};
