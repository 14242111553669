export const BottleIcon = ({ width, height, fill }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 32 32"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <title>Bottle</title>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(7.957447, 2.000000)" fill={fill}>
                <path d="M3.41327509,10.2849362 C2.88723253,10.2849362 2.38501977,10.448766 1.96204104,10.7591489 C1.21259424,11.3102128 0.880764448,12.3080851 1.11846658,13.3029787 C1.94536019,16.7821277 1.94536019,20.3101277 1.11846658,23.7892766 C0.880764448,24.7835745 1.21259424,25.7820426 1.96204104,26.3325106 C2.38501977,26.6434894 2.88723253,26.8073191 3.41327509,26.8073191 L12.1069772,26.8073191 C12.8862113,26.8073191 13.6273176,26.4314043 14.088424,25.8017021 C14.4667219,25.2857872 14.5936155,24.5822128 14.4357432,23.8726809 C13.5933602,20.0676596 13.5838283,16.5956596 14.4065517,13.2588936 C14.6877432,12.1162553 14.1098708,10.9205957 13.0625517,10.4779574 C12.7605091,10.3498723 12.438807,10.2849362 12.1069772,10.2849362 L3.41327509,10.2849362 Z M12.1069772,27.8498723 L3.41327509,27.8498723 C2.66323253,27.8498723 1.94774317,27.6157447 1.34484955,27.1731064 C0.257019767,26.3742128 -0.230299382,24.9509787 0.103913384,23.548 C0.893275086,20.2291064 0.893275086,16.8631489 0.103913384,13.5442553 C-0.230299382,12.1412766 0.257019767,10.7180426 1.3454453,9.91914894 C1.94774317,9.47651064 2.66323253,9.24238298 3.41327509,9.24238298 L12.1069772,9.24238298 C12.578807,9.24238298 13.0375304,9.3347234 13.4682538,9.51761702 C14.9832325,10.1574468 15.8214453,11.8737872 15.4187219,13.5085106 C14.6353176,16.6862128 14.6472325,20.0021277 15.4538708,23.6474894 C15.6760836,24.6501277 15.4848496,25.6593191 14.9296155,26.4177021 C14.2725091,27.3148936 13.2168496,27.8498723 12.1069772,27.8498723 L12.1069772,27.8498723 Z"></path>
                <path d="M4.19548785,15.6984681 L1.22033892,15.6984681 C1.05531764,15.6984681 0.922466576,15.565617 0.922466576,15.4005957 C0.922466576,15.2361702 1.05531764,15.1027234 1.22033892,15.1027234 L4.19548785,15.1027234 C4.35991338,15.1027234 4.49336019,15.2361702 4.49336019,15.4005957 C4.49336019,15.565617 4.35991338,15.6984681 4.19548785,15.6984681"></path>
                <path d="M4.19548785,20.7098723 L1.22033892,20.7098723 C1.05531764,20.7098723 0.922466576,20.5764255 0.922466576,20.412 C0.922466576,20.2475745 1.05531764,20.1141277 1.22033892,20.1141277 L4.19548785,20.1141277 C4.35991338,20.1141277 4.49336019,20.2475745 4.49336019,20.412 C4.49336019,20.5764255 4.35991338,20.7098723 4.19548785,20.7098723"></path>
                <path d="M2.21642402,8.83608511 L12.690807,8.83608511 L12.690807,7.11080851 L2.21642402,7.11080851 L2.21642402,8.83608511 Z M13.2120836,9.8786383 L1.69514743,9.8786383 C1.406807,9.8786383 1.17387083,9.64510638 1.17387083,9.3573617 L1.17387083,6.58953191 C1.17387083,6.30119149 1.406807,6.06825532 1.69514743,6.06825532 L13.2120836,6.06825532 C13.4998283,6.06825532 13.7333602,6.30119149 13.7333602,6.58953191 L13.7333602,9.3573617 C13.7333602,9.64510638 13.4998283,9.8786383 13.2120836,9.8786383 L13.2120836,9.8786383 Z"></path>
                <path d="M3.68255168,5.76978723 L11.2693602,5.76978723 C11.0685942,4.20417021 9.78118998,3.45531915 8.94178572,3.12825532 L8.90425381,3.13957447 L8.87804104,3.10382979 C8.87506232,3.1026383 8.8720836,3.10144681 8.86970062,3.10025532 L8.46876445,2.9507234 L8.53667934,2.52893617 C8.54918998,2.45268085 8.56825381,2.38476596 8.59327509,2.32459574 C8.57063679,1.04255319 8.02255168,1.04255319 7.62042402,1.04255319 C7.22425381,1.04255319 6.68629636,1.04255319 6.64936019,2.27157447 C6.66842402,2.32161702 6.68391338,2.37940426 6.69284955,2.44612766 L6.75599849,2.8893617 L6.32706232,3.0186383 C4.78884955,3.48093617 3.84518998,4.47165957 3.68255168,5.76978723 Z M11.8126793,6.81234043 L2.63701977,6.81234043 L2.62033892,6.30714894 C2.6161687,6.24757447 2.61497721,6.21421277 2.61497721,6.18025532 C2.61497721,4.34834043 3.72604104,2.87148936 5.61097721,2.16076596 C5.69795594,0.38306383 6.73991338,0 7.62042402,0 C8.52178572,0 9.59174317,0.40093617 9.63523253,2.28885106 C11.3599134,3.06868085 12.3399134,4.46748936 12.3399134,6.18025532 L12.3351474,6.27795745 L12.298807,6.75991489 L11.8126793,6.81234043 L11.8126793,6.81234043 Z"></path>
            </g>
        </g>
    </svg>
);

BottleIcon.defaultProps = {
    width: '32px',
    height: '32px',
    fill: '#000000',
};
