import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext, useEffect } from 'react';
import './App.css';
import Entries from './components/Entries';
import { Footer } from './components/Footer';
import Header from './components/Header';
import Login from './components/Login';
import { StoreContext } from './services/Store';
import { userService } from './services/user';

const useStyles = makeStyles((theme) => ({
    appBar: {
        paddingTop: 'env(safe-area-inset-top)',
    },
}));

function App() {
    const classes = useStyles();
    const [state, dispatch] = useContext(StoreContext);
    const { isLoggedIn } = state.user;

    useEffect(() => {
        const userData = userService.getLocalUserData();
        if (userData) {
            dispatch({ type: 'LOG_IN', payload: userData });
            userService.validate().then((success) => {
                if (!success) {
                    dispatch({ type: 'LOG_OUT' });
                    userService.logout();
                }
            });
        }
    }, [dispatch]);

    return (
        <Box bgcolor="white">
            <Header />
            {!isLoggedIn && <Login />}
            {isLoggedIn && <Entries />}
            <Footer />
        </Box>
    );
}

export default App;
