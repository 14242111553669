import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { getTimeFromDate } from '../../../utils/date';

export const DefaultItem = ({ log }) => {
    const time = getTimeFromDate(log.started_at || log.ended_at || log.updated_at);
    return (
        <ListItem key={`item-${log.id}`}>
            <ListItemIcon></ListItemIcon>
            <ListItemText primary={time} />
        </ListItem>
    );
};
