import { BottleItem } from './items/Bottlel';
import { DefaultItem } from './items/Default';
import { DiaperItem } from './items/Diaper';
import { OutdoorItem } from './items/Outdoor';
import { SleepItem } from './items/Sleep';

export const itemMap = new Map([
    ['bottle', BottleItem],
    ['sleep', SleepItem],
    ['outdoor', OutdoorItem],
    ['diaper', DiaperItem],
    ['default', DefaultItem],
]);
