import { ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import { getTimeFromDate } from '../../../utils/date';
import { BottleIcon } from '../../Icons/BottleIcon';

export const BottleItem = ({ log }) => {
    const time = getTimeFromDate(log.started_at || log.ended_at || log.updated_at);

    return (
        <ListItem>
            <ListItemIcon>
                <BottleIcon width="40px" height="40px" />
            </ListItemIcon>
            <ListItemText
                primary={`${time} Flasche`}
                primaryTypographyProps={{ variant: 'body1' }}
                secondary={
                    <>
                        <Typography variant="body1">
                            {log.amount || 0}ml
                            {log.description && ` – ${log.description}`}
                        </Typography>
                        {log.note && <Typography variant="body2">{log.note}</Typography>}
                    </>
                }
            />
        </ListItem>
    );
};
