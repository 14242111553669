import { utcToZonedTime } from 'date-fns-tz';
import format from 'date-fns/format';
import formatDuration from 'date-fns/formatDuration';
import intervalToDuration from 'date-fns/intervalToDuration';
import de from 'date-fns/locale/de';
import parseJSON from 'date-fns/parseJSON';

export const getTimeFromDate = (strDate) => {
    if (!strDate) return;
    const date = utcToZonedTime(parseJSON(strDate), 'UTM');
    return format(date, 'HH:mm', { locale: de });
};

export const getTimeDistance = (strDateFrom, strDateTo) => {
    if (!strDateFrom || !strDateTo) return;

    const dateFrom = utcToZonedTime(parseJSON(strDateFrom), 'UTM');
    const dateTo = utcToZonedTime(parseJSON(strDateTo), 'UTM');

    const duration = intervalToDuration({ start: dateFrom, end: dateTo });

    return formatDuration(duration, { locale: de, format: ['hours', 'minutes'] });
};
