import React, { createContext, useReducer } from 'react';

const appReducer = (state, action) => {
    switch (action.type) {
        case 'LOG_IN':
            return {
                ...state,
                user: {
                    ...action.payload,
                    isLoggedIn: true,
                },
            };
        case 'LOG_OUT':
            return {
                ...state,
                user: {
                    isLoggedIn: false,
                },
            };
        default:
            return state;
    }
};

const initialState = {
    user: {},
};
export const StoreContext = createContext(initialState);

export const Store = ({ children }) => {
    const [state, dispatch] = useReducer(appReducer, initialState);
    return <StoreContext.Provider value={[state, dispatch]}>{children}</StoreContext.Provider>;
};
