import { AppBar, Box, IconButton, makeStyles, Toolbar, Typography } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import formatDuration from 'date-fns/formatDuration';
import intervalToDuration from 'date-fns/intervalToDuration';
import de from 'date-fns/locale/de';
import parseJSON from 'date-fns/parseJSON';
import React, { useContext } from 'react';
import { StoreContext } from '../../services/Store';
import { events } from '../../utils/events';
import { BabyIcon } from '../Icons/BabyIcon';

const useStyles = makeStyles((theme) => ({
    appBar: {
        height: 72,
        boxSizing: 'content-box',
        paddingTop: 'env(safe-area-inset-top)',
    },
    toolbar: {
        minHeight: 72,
    },
    title: {
        flexGrow: 1,
    },
}));

export const Header = () => {
    const classes = useStyles();
    const [state] = useContext(StoreContext);
    const { isLoggedIn } = state.user;

    const ageIntervals =
        state.user.baby &&
        intervalToDuration({
            start: parseJSON(state.user.baby?.birthday),
            end: new Date(),
        });
    const babyAge = ageIntervals && formatDuration(ageIntervals, { locale: de, format: ['months', 'weeks', 'days'] });

    const handleRefresh = () => {
        events.sendRefreshEntries();
    };

    return (
        <AppBar position="fixed" color="primary" className={classes.appBar}>
            <Toolbar variant="regular" className={classes.toolbar}>
                {isLoggedIn && (
                    <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                        <BabyIcon fill="#ffffff" />
                    </IconButton>
                )}
                {isLoggedIn && (
                    <Box className={classes.title}>
                        <Typography variant="h6" color="inherit">
                            {state.user.baby?.name}
                        </Typography>
                        <Typography variant="subtitle2">{babyAge}</Typography>
                    </Box>
                )}
                {!isLoggedIn && (
                    <Typography variant="h6" color="inherit" className={classes.title}>
                        Baby Log
                    </Typography>
                )}
                <IconButton aria-label="refresh entries" onClick={handleRefresh} color="inherit">
                    <RefreshIcon />
                </IconButton>
            </Toolbar>
        </AppBar>
    );
};
