import { BottomNavigation, BottomNavigationAction, makeStyles } from '@material-ui/core';
import React from 'react';
import { BottleIcon } from '../Icons/BottleIcon';
import { DiaperIcon } from '../Icons/DiaperIcon';
import { SleepIcon } from '../Icons/SleepIcon';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
    },
}));

export const Footer = () => {
    const classes = useStyles();

    return (
        <BottomNavigation className={classes.root} showLabels>
            <BottomNavigationAction label="Flasche" value="bottle" icon={<BottleIcon />} />
            <BottomNavigationAction label="Diaper" value="diaper" icon={<DiaperIcon />} />
            <BottomNavigationAction label="Sleep" value="sleep" icon={<SleepIcon />} />
        </BottomNavigation>
    );
};
