import { ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import { getTimeDistance, getTimeFromDate } from '../../../utils/date';
import { OutdoorIcon } from '../../Icons/OutdoorIcon';

export const OutdoorItem = ({ log }) => {
    const timeStart = getTimeFromDate(log.started_at);
    const timeEnd = getTimeFromDate(log.ended_at);
    const hours = getTimeDistance(log.started_at, log.ended_at);

    return (
        <ListItem>
            <ListItemIcon>
                <OutdoorIcon width="40px" height="40px" />
            </ListItemIcon>
            <ListItemText
                primary={`${hours} Draußen`}
                primaryTypographyProps={{ variant: 'body1' }}
                secondary={
                    <>
                        <Typography variant="body1">{`${timeStart} bis ${timeEnd}`}</Typography>
                        {log.note && <Typography variant="body2">{log.note}</Typography>}
                    </>
                }
            />
        </ListItem>
    );
};
